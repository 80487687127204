import { create } from "zustand";
import { ItemService } from "../service";
import { showSuccess } from "../widgets";
import { getFormData } from "../actions";
// import { useShallow } from 'zustand/react/shallow'

const store = {
  itemId: null,
  formSchema: null,
  formInstance: null,
  formData: null,
  manuallyAddingQuotes: false,
  showQuotationTable: false, // Disable Send RFQ / Manually add quotes btn if true
  releasingPO: null, // Show loader for action button in the selected row and disable other "Release PO" btn
  generatingPO: null, // Show loader for action button in the selected row and disable other "Release PO" btn
};

const useFormStore = create((set, get) => ({
  ...store,
  setItemId: (id) => set(() => ({ itemId: id })),
  setFormInstance: (data) => set(() => ({ formInstance: data })),
  setFormSchema: (data) => set(() => ({ formSchema: data })),
  setFormData: (data) => set(() => ({ formData: data })),
  addRow: (connected_model_id) => {
    let _item_id = get().itemId;
    let _schema = get().formSchema;
    let formInstance = get().formInstance;
    let formData=get().formData
    ItemService.createLineItem(
      _schema.metadataId,
      _item_id,
      connected_model_id
    ).then((res) => {
      console.log(res);
  
      let _currentRows = formInstance.getFieldValue(connected_model_id) || [];
  
      const newRow = { id: res.id };
  
      formInstance.setFieldValue(connected_model_id, [
        ..._currentRows,
        newRow,
      ]);
      formData[connected_model_id].push(newRow)
      set(() => ({ formData: formData }))
    })
    .catch((error) => {
      console.error("Error adding row:", error);
    });
  },
    
  addBatchRows: (connected_model_id, batchData, onSuccess, onError) => {
    let _item_id = get().itemId;
    let _schema = get().formSchema;
    let formInstance = get().formInstance;
    let formData=get().formData

    ItemService.createBatch(_schema.metadataId, _item_id, connected_model_id, batchData)
    .then((res) => {
      const currentRows = formInstance.getFieldValue(connected_model_id) || [];
      const newRows = Object.values(res).map((item) => ({
        id: item.id,
        description: item.description,
        sku_code: item.sku_code,
        sku_name: item.sku_name,
        quantity: item.quantity,
        brand: item.brand,
      }));
      

      const uniqueNewRows = newRows.filter(newRow =>
        !currentRows.some(existingRow => existingRow.id === newRow.id)
      );

      formInstance.setFieldValue(connected_model_id, [...currentRows, ...uniqueNewRows]);
      formData[connected_model_id]=[...formData[connected_model_id],...uniqueNewRows]
      set(() => ({ formData: formData }))
         if (onSuccess) {
          onSuccess();
        }
    })
      .catch((error) => {
        console.error("Error adding batch rows:", error,error.missing_item_codes);
        console.log("Error adding batch rows:",error.missing_item_codes)
        if (onError) {
          let errorMsg;
       if (error.response?.status === 404) {
            if (Array.isArray(error.response.data)) {
              errorMsg = error.response.data.join(", ");
            } else {
              errorMsg = "Not Found: The requested resource was not found.";
            }
          } else {
            errorMsg = "An unexpected error occurred.";
          }
          let errorData = {
            message: "Import failed",
            missing_item_codes: error.missing_item_codes || []
          };
          onError(errorData);
        }
      });
  },

  deleteRow: (connected_model_id, row_index) => {
    let _item_id = get().itemId;
    let _schema = get().formSchema;
    let datatable = get().formData;
    console.log("row_index",datatable)
    console.log("row_index",row_index)
    console.log("row_index",datatable[connected_model_id][row_index])
    const connected_model_row_id = datatable[connected_model_id][row_index]?.id;

    ItemService.deleteLineItem(
      _schema.metadataId,
      _item_id,
      connected_model_id,
      connected_model_row_id
    ).then((res) => {
      console.log(res);
        // const updatedRows = datatable[connected_model_id].filter((_, index) => index !== row_index);
        // set((state) => ({
        //   formData: {
        //     ...state.formData,
        //     [connected_model_id]: updatedRows
        //   }
        // }));
        // let formInstance = get().formInstance;
        // formInstance.setFieldValue(connected_model_id, updatedRows);
        getFormData(_schema.metadataId,_item_id);
        showSuccess("Item Deleted")
      })
      .catch((error) => {
        console.error("Error deleting row:", error);
      });
    },
    
  setManuallyAddingQuotes: (manuallyAddingQuotes) => {
    set(() => ({ manuallyAddingQuotes: manuallyAddingQuotes }));
  },
  setShowQuotationTable: (showQuotationTable) => {
    set(() => ({ showQuotationTable: showQuotationTable }));
  },
  setReleasingPO: (releasingPO) => set(() => ({ releasingPO: releasingPO })),
  setGeneratingPO: (generatingPO) => set(() => ({ generatingPO: generatingPO })),
  reset: () => set({ ...store }),
}));

export { useFormStore };
