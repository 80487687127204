import {
  EditIcon,
  Upload,
  Button,
  TextIcon,
  TextareaIcon,
  EmailIcon,
  DateFieldIcon,
  DateTimeIcon,
  NumberIcon,
  CurrencyIcon,
  DropdownFieldIcon,
  MultiSelectDropdownIcon,
  CheckboxIcon,
  UserIcon,
} from "../widgets";

export const FIELD_TYPE = {
  TEXT: "text",
  TEXT_AREA: "textarea",
  EMAIL: "email",
  DATE: "date",
  DATE_TIME: "datetime",
  NUMBER: "number",
  CURRENCY: "currency",
  DROPDOWN: "dropdown",
  MULTISELECT: "multiselect",
  ATTACHMENT: "attachment",
  USER: "user",
  MULTIUSER: "multiuser",
  CHECKBOX: "checkbox",
  YESNO: "yesno",
  ADDRESS: "address",
  DOCUMENT: "document",
};

export const FIELD_KEYS = [
  "text",
  "textarea",
  "email",
  "date",
  "datetime",
  "number",
  "currency",
  "dropdown",
  "multiselect",
  "attachment",
  "user",
  "multiuser",
  "checkbox",
  "yesno",
  "address",
  "document",
];

export const FIELD_ICONS = {
  text: TextIcon,
  textarea: TextareaIcon,
  email: EmailIcon,
  date: DateFieldIcon,
  datetime: DateTimeIcon,
  number: NumberIcon,
  currency: CurrencyIcon,
  dropdown: DropdownFieldIcon,
  multiselect: MultiSelectDropdownIcon,
  attachment: TextIcon,
  user: UserIcon,
  multiuser: UserIcon,
  checkbox: CheckboxIcon,
  yesno: TextIcon,
  address: TextIcon,
  document: TextIcon,
};

export const FIELD_DETAILS = [
  {
    fieldType: "text",
    title: "Text",
    name: "Short Text",
  },
  {
    fieldType: "textarea",
    title: "Long text",
    name: "Description of the item",
  },
  {
    fieldType: "email",
    title: "Email",
    name: "Mention your email",
  },
  {
    fieldType: "user",
    title: "User",
    name: "Add users",
  },
  {
    fieldType: "multiuser",
    title: "Multiple Users",
    name: "Add users",
  },
  {
    fieldType: "date",
    title: "Date",
    name: "Mention the date",
  },
  {
    fieldType: "datetime",
    title: "Date Time",
    name: "Mention date and time",
  },
  {
    fieldType: "number",
    title: "Number",
    name: "No of items",
  },
  {
    fieldType: "currency",
    title: "Currency",
    name: "Amount",
  },
  {
    fieldType: "dropdown",
    title: "Select",
    name: "Department",
  },
  {
    fieldType: "multiselect",
    title: "MultiSelect",
    name: "Items to deliver",
  },
  {
    fieldType: "checkbox",
    title: "Checkbox",
    name: "Fast delivery",
  },
  {
    fieldType: "attachment",
    title: "Attachment",
    name: "Attachment",
  },
  {
    fieldType: "yesno",
    title: "YesNo",
    name: "YesNo",
  },
  {
    fieldType: "document",
    title: "Document",
    name: "Document",
  },
  {
    fieldType: "address",
    title: "address",
    name: "address",
  },
];

export const OTHER_FIELDS = {
  MULTI_USER: "multiuser",
  RADIO: "radio",
  PHONENUMBER: "phonenumber",
};
