import { create } from "zustand";
import { enableMapSet, produce } from "immer";
import { useAccountStore } from "./account.store";
import { WorkflowService } from "../service";
import { showError } from "../widgets";
import { getDraftFormSchema, getFormSchema } from "../actions/form";

enableMapSet();

const initialState = {
  flowId: null,
  metadata: null,
  editingField: null,
  isTable: false,
  status: "published", // published or draft
};

const useFormBuilderStore = create((set, get) => ({
  ...initialState,
  init: (flowId = "purchase_order") => {
    useAccountStore.getState().setLoading(true);
    WorkflowService.getDraftMetadata(flowId)
      .then((metadata) => {
        let data, defaultActiveField;
        data = metadata;
        const phaseId = data.workflow.phases[0];
        const column = data[data[data[phaseId].rows[0]].columns[0]];

        defaultActiveField = data[column["field"]];
        set(() => ({
          metadata: data,
          editingField: defaultActiveField,
          flowId: flowId,
          status: "draft",
        }));
      })
      .catch((err) => showError(err.message))
      .finally(() => {
        useAccountStore.getState().setLoading(false);
      });
  },
  getMetadata: (flowId) => {
    const _metadata = get().metadata;
    if (_metadata) {
      return _metadata;
    } else {
      set(() => ({ flowId: flowId }));
      getDraftFormSchema(flowId);
    }
  },
  setMetadata: (data) => set(() => ({ metadata: data })),
  setEditingField: (field, isTable = false) => {
    set(() => ({ editingField: field, isTable: isTable }));
  },
  setStatus: (status) => set({ status: status }),
  addField: (phaseId, data, index) => {
    let { rowId, columnId, fieldId } = { ...data };
    let {
      row: rowData,
      col: colData,
      field: fieldData,
    } = createMetadataRow(phaseId, rowId, columnId, fieldId, data);
    set(
      produce((state) => {
        state.editingField = fieldData;
        if (index) {
          state.metadata[phaseId].rows.splice(index, 0, rowData.id);
        } else {
          state.metadata[phaseId].rows.push(rowData.id);
        }
        state.metadata[state.metadata.metadataId].fields.push(fieldData.id);
        state.metadata[rowData.id] = rowData;
        state.metadata[colData.id] = colData;
        state.metadata[fieldData.id] = fieldData;
      })
    );
    WorkflowService.saveDraftMetadata(
      useFormBuilderStore.getState().flowId,
      useFormBuilderStore.getState().metadata
    )
      .then(() => {
        set(() => ({ status: "draft" }));
      })
      .catch((err) => showError(err.message));
  },
  removeField: (rowId) => {
    set(
      produce((state) => {
        const phaseId = state.metadata[rowId].phase;
        let rowIndex = state.metadata[phaseId].rows.findIndex(
          (r) => r === rowId
        );
        if (rowIndex > -1) {
          state.metadata[phaseId].rows.splice(rowIndex, 1);

          const fieldIndex = state.metadata[
            state.metadata.metadataId
          ].fields.findIndex(
            (fieldId) =>
              fieldId === state.metadata[state.metadata[rowId].columns[0]].field
          );
          state.metadata[state.metadata.metadataId].fields.splice(
            fieldIndex,
            1
          );

          delete state.metadata[
            state.metadata[state.metadata[rowId].columns[0]].field
          ];
          delete state.metadata[state.metadata[rowId].columns[0]];
          delete state.metadata[rowId];
        }
      })
    );
    WorkflowService.saveDraftMetadata(
      useFormBuilderStore.getState().flowId,
      useFormBuilderStore.getState().metadata
    )
      .then(() => {
        set(() => ({ status: "draft" }));
      })
      .catch((err) => showError(err.message));
  },
  moveRows: (fromPhase, toPhase, rowId, toIndex) => {
    set(
      produce((state) => {
        if (fromPhase !== toPhase) {
          let _srcRows = [...state.metadata[fromPhase].rows];
          let _destRows = [...state.metadata[toPhase].rows];
          let rowIndex = _srcRows.indexOf(rowId);

          if (toIndex > -1) {
            _srcRows.splice(rowIndex, 1);
            _destRows.splice(toIndex, 0, rowId);
            state.metadata[fromPhase].rows = _srcRows;
            state.metadata[toPhase].rows = _destRows;
            if (state.metadata[state.metadata[rowId].columns[0]]?.field) {
              state.metadata[
                state.metadata[state.metadata[rowId].columns[0]].field
              ].phase = toPhase;
            } else {
              state.metadata[
                state.metadata[state.metadata[rowId].columns[0]].connectedModel
              ].phase = toPhase;
            }
            state.metadata[rowId].phase = toPhase;
          }
        } else {
          let _rows = [...state.metadata[fromPhase].rows];
          let rowIndex = _rows.indexOf(rowId);
          if (toIndex > -1) {
            _rows.splice(toIndex, 0, _rows.splice(rowIndex, 1)[0]);
            state.metadata[fromPhase].rows = _rows;
          }
        }
      })
    );
    WorkflowService.saveDraftMetadata(
      useFormBuilderStore.getState().flowId,
      useFormBuilderStore.getState().metadata
    )
      .then(() => {
        set(() => ({ status: "draft" }));
      })
      .catch((err) => showError(err.message));
  },
  updateField: (fieldId, change) => {
    set(
      produce((state) => {
        state.metadata[fieldId] = { ...state.metadata[fieldId], ...change };
        state.editingField = { ...state.metadata[fieldId], ...change };
        state.status = "draft";
      })
    );
    WorkflowService.saveDraftMetadata(
      useFormBuilderStore.getState().flowId,
      useFormBuilderStore.getState().metadata
    )
      .then(() => {
        set(() => ({ status: "draft" }));
      })
      .catch((err) => showError(err.message));
  },
  updateFieldId: (oldFiledId, newFieldId) => {
    set(
      produce((state) => {
        let newFieldObj = {
          ...state.metadata[oldFiledId],
        };
        newFieldObj.id = newFieldId;
        let columnId = state.metadata[oldFiledId].columnId;
        state.metadata[state.flowId].fields = state.metadata[
          state.flowId
        ].fields.map((_fieldId) =>
          _fieldId === oldFiledId ? newFieldId : _fieldId
        );
        Reflect.deleteProperty(state.metadata, oldFiledId);
        state.metadata[newFieldId] = newFieldObj;
        state.metadata[columnId] = {
          ...state.metadata[columnId],
          field: newFieldId,
        };
        state.status = "draft";
      })
    );
    WorkflowService.saveDraftMetadata(
      useFormBuilderStore.getState().flowId,
      useFormBuilderStore.getState().metadata
    )
      .then(() => {
        set(() => ({ status: "draft" }));
      })
      .catch((err) => showError(err.message));
  },
  addTable: (phaseId, data) => {},
  updateTable: (tableId, data) => {},
  deleteTable: (tableId) => {},
  updateTableId: (oldTableId, newTableId) => {},
  addTableField: (tableId, data) => {},
  updateTableField: (tableId, fieldId, data) => {},
  deleteTableField: (tableId, fieldId) => {},
  updateTableFieldId: (tableId, oldTableId, newTableId) => {},
  onPublish: () => {
    WorkflowService.saveLiveMetadata(
      useFormBuilderStore.getState().flowId,
      useFormBuilderStore.getState().metadata
    )
      .then(() => {
        set(() => ({ status: "live" }));
        getFormSchema(get().flowId);
      })
      .catch((err) => showError(err.message));
  },
  reset: () => set({ ...initialState }),
}));

function createMetadataRow(phaseId, rowId, colId, fieldId, fieldData) {
  return {
    row: {
      id: rowId,
      type: "row",
      columns: [colId],
      phase: phaseId,
    },
    col: {
      id: colId,
      rowId: rowId,
      type: "column",
      field: fieldId,
    },
    field: {
      id: fieldId,
      type: "field",
      columnId: colId,
      name: fieldData["name"] || "",
      fieldType: fieldData["fieldType"],
      phase: phaseId,
    },
  };
}

export { useFormBuilderStore };
