import { WorkflowService } from "../service";
import { SupplierService } from "../service/supplier.service";
import { useSupplierStore } from "../store";
import { showError } from "../widgets";

export function getSupplierSchema(tableId) {
  const setSupplierSchema = useSupplierStore.getState().setSupplierSchema;
  WorkflowService.getLiveMetadata(tableId)
    .then((res) => {
      setSupplierSchema(res);
    })
    .catch((err) => showError("Can't find supplier schema"));
}

export function getSuppliers() {
  const setSuppliers = useSupplierStore.getState().setSuppliers;
  SupplierService.getSupplierData()
    .then((data) => {
      setSuppliers([...data]);
    })
    .catch((err) => showError("Error in fetching supplier data"));
}
