import React, { useEffect, useState } from "react";
import {
  Input as AntInput,
  InputNumber as AntInputNumber,
  ConfigProvider,
} from "antd";

import { Select, SelectItem } from "../select";
import { Icon, SearchIcon } from "../icon";

// import "antd/es/input/style/css";
import styles from "./input.module.css";
const { TextArea, Password, Search } = AntInput;

export function Input({ ...props }) {
  return (
    <ConfigProvider
      wave={{ disabled: true }}
      theme={{
        components: {
          Input: {
            hoverBorderColor: "rgba(127, 86, 217, 0.5)",
            activeBorderColor: "rgba(127, 86, 217, 0.8)",
            activeShadow: "rgba(127, 86, 217, 0.4)",
          },
        },
      }}
    >
      <AntInput {...props} />
    </ConfigProvider>
  );
}

export function EnegmaSearch({ placeholder, ...props }) {
  return (
    <Input
      className={styles.customSearch}
      prefix={<Icon src={SearchIcon} />}
      placeholder={placeholder}
      {...props}
    />
  );
}

export { TextArea, Password, Search };

export function Number({ ...props }) {
  return <AntInputNumber {...props} />;
}

export function Currency({ value, onChange, disabled, ...rest }) {
  // State for the input value and the selected currency
  const [amount, setAmount] = useState("");
  const [currency, setCurrency] = useState("INR"); // Default currency

  const handleCurrencyChange = (value) => {
    setCurrency(value); // Update currency selection
    onChange(`${amount} ${value}`);
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value); // Update the numeric amount
    onChange(`${e.target.value} ${currency}`);
  };

  const currencyOptions = [
    { label: "INR", value: "INR" },
    { label: "USD", value: "USD" },
    { label: "EUR", value: "EUR" },
    { label: "GBP", value: "GBP" },
  ];

  useEffect(() => {
    if (value?.dv) {
      setAmount(value?.v);
      setCurrency(value?.u);
    }
    if (value?.length > 0) {
      let [_amount, _currency] = value.split(" ");
      setAmount(_amount);
      setCurrency(_currency);
    }
  }, [value]);

  return (
    <Input
      type="number" // Numeric input type
      value={amount} // Numeric amount value
      onChange={handleAmountChange} // Handle change in number input
      placeholder="Enter amount"
      addonAfter={
        <Select
          value={currency}
          onChange={handleCurrencyChange}
          disabled={disabled}
        >
          {currencyOptions.map((option) => (
            <SelectItem key={option.value} value={option.value}>
              {option.label}
            </SelectItem>
          ))}
        </Select>
      } // Currency dropdown as addonAfter
      disabled={disabled}
      {...rest}
    />
  );
}
