import { create } from "zustand";
import {
  getProductSchema,
  getProducts,
  getSupplierCatalogSchema,
  getSupplierCatalogs,
} from "../actions/inventory";

const initialState = {
  productSchema: null,
  products: [],
  supplierCatalogs: [],
  supplierCatalogSchema: null,
};

const useInventoryStore = create((set, get) => ({
  ...initialState,
  getProductSchema: (tableId) => {
    const _schema = get().productSchema;
    if (!_schema) {
      getProductSchema(tableId);
    }
  },
  setProductSchema: (data) => set(() => ({ productSchema: data })),
  getProducts: () => {
    const _products = get().products;
    if (_products.length === 0) {
      getProducts();
    }
  },
  setProducts: (data) => {
    set(() => ({
      products: data,
    }));
  },
  getSupplierCatalogSchema: (tableId) => {
    const _schema = get().supplierCatalogSchema;
    if (!_schema) {
      getSupplierCatalogSchema(tableId);
    }
  },
  setSupplierCatalogSchema: (data) =>
    set(() => ({ supplierCatalogSchema: data })),
  getSupplierCatalogs: () => {
    const _supplierCatalogs = [];
    if (_supplierCatalogs.length === 0) {
      getSupplierCatalogs();
    }
  },
  setSupplierCatalogs: (data) => {
    set(() => ({
      supplierCatalogs: data,
    }));
  },

  reset: () => set(() => ({ ...initialState })),
}));

export { useInventoryStore };
