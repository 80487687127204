import { post, get, put, deleteReq } from "./service";

export const AiService = {
  suggest(query) {
    return get(`/procurement/search-query?query=${query}`);
  },
  intake(data) {
    return post(`/procurement/intake`, { body: data });
  },
  //   getUsers() {
  //     return get("/user/GetUsers");
  //   },
};

// {
//   smart_attachment: "attachment_key",
//   query: "hbjhb"
// }
