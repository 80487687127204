import { InventoryService } from "../service/inventory.service.js";
import { WorkflowService } from "../service/workflow_service.js";
import { useInventoryStore } from "../store";
import { showError } from "../widgets";

export function getProductSchema(tableId) {
  const setProductSchema = useInventoryStore.getState().setProductSchema;
  WorkflowService.getLiveMetadata(tableId)
    .then((res) => {
      setProductSchema(res);
    })
    .catch((err) => showError("Can't find product schema"));
}

export function getProducts() {
  const setProducts = useInventoryStore.getState().setProducts;
  InventoryService.getProductsData()
    .then((data) => {
      setProducts(data);
    })
    .catch((err) => showError("Error in fetching products"));
}

export function getSupplierCatalogSchema(tableId) {
  const setSupplierCatalogSchema =
    useInventoryStore.getState().setSupplierCatalogSchema;
  WorkflowService.getLiveMetadata(tableId)
    .then((res) => {
      setSupplierCatalogSchema(res);
    })
    .catch((err) => showError("Can't find product schema"));
}

export function getSupplierCatalogs() {
  const setSupplierCatalogs = useInventoryStore.getState().setSupplierCatalogs;
  InventoryService.getSupplierCatalog()
    .then((data) => {
      setSupplierCatalogs(data);
    })
    .catch((err) => showError("Error in fetching supplier catalogs"));
}
