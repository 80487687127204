import { create } from "zustand";
import { getSuppliers, getSupplierSchema } from "../actions/supplier";
import { WorkflowService } from "../service";
import { showError, showSuccess } from "../widgets";

const initialState = {
  suppliers: [],
  supplierSchema: null,
};

const useSupplierStore = create((set, get) => ({
  ...initialState,
  getSupplierSchema: (tableId) => {
    const _schema = get().supplierSchema;
    if (!_schema) {
      getSupplierSchema(tableId);
    }
  },
  setSupplierSchema: (data) => set(() => ({ supplierSchema: data })),
  addColumn: (tableId, fieldName, fieldType, required) => {
    if (fieldName && fieldType) {
      const _schema = get().supplierSchema;
      const fieldId = fieldName.toLowerCase().split(" ").join("_");
      const field = {
        id: fieldId,
        name: fieldName,
        fieldType: fieldType,
        isRequired: required,
      };
      _schema[tableId].fields.push(fieldId);
      _schema[fieldId] = field;

      WorkflowService.saveDraftMetadata(tableId, _schema).catch((err) =>
        showError("Failed to save draft!")
      );
      WorkflowService.saveLiveMetadata(tableId, _schema)
        .then(() => {
          showSuccess("Column added!");
          getSupplierSchema(tableId);
        })
        .catch((err) => {
          showError("Failed to add column");
          console.log(err);
        });
    }
  },
  getSuppliers: () => {
    const _suppliers = get().suppliers;
    if (_suppliers.length === 0) {
      getSuppliers();
    }
  },
  setSuppliers: (supplierList) => {
    set(() => ({
      suppliers: supplierList,
    }));
  },
  addSupplier: (supplier) =>
    set((state) => ({
      suppliers: [...state.suppliers, supplier],
    })),
  deleteSupplier: (supplierId) =>
    set((state) => ({
      suppliers: state.suppliers.filter(
        (supplier) => supplier.supplier_id !== supplierId
      ),
    })),
  addBulkSupplier: (supplierList) =>
    set((state) => {
      const newSuppliers = [...state.suppliers, ...supplierList];
      return { suppliers: newSuppliers };
    }),
  updateSupplier: (updatedSupplier) =>
    set((state) => ({
      suppliers: state.suppliers.map((supplier) =>
        supplier.supplier_id === updatedSupplier.supplier_id
          ? { ...supplier, ...updatedSupplier }
          : supplier
      ),
    })),
  reset: () => set(() => ({ ...initialState })),
}));

export { useSupplierStore };
