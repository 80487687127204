import { post, get, put, deleteReq } from "./service";

export const InventoryService = {
  createProduct(data) {
    return post(`/product`, { body: data });
  },
  createSupplierCatalog(data) {
    return post(`/supplier_catalog`, { body: data });
  },
  deleteProduct(product_id) {
    return deleteReq(`/product/${product_id}`, {}, true);
  },
  deleteSupplierCatalog(id) {
    return deleteReq(`/supplier_catalog/${id}`, {}, true);
  },
  getProductsData() {
    return get(`/product/all`);
  },
  getSupplierCatalog() {
    return get(`/supplier_catalog/all`);
  },
  updateProductData(product_id, data) {
    return put(`/product/${product_id}`, { body: data });
  },
  updateSupplierCatalog(id, data) {
    return put(`/supplier_catalog/${id}`, { body: data });
  },
  createBulkProducts(products_list) {
    return post(`/product/bulk`, { body: products_list });
  },
  createBulkSupplierCatalogs(items_list) {
    return post(`/supplier_catalog/bulk`, { body: items_list });
  },
};
