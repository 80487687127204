import { isEqual } from "lodash";
import { ItemService, WorkflowService } from "../service";
import { useAccountStore, useFormBuilderStore, useFormStore } from "../store";
import { showError } from "../widgets";

export function getFormData(id, itemId) {
  const setFormData = useFormStore.getState().setFormData;
  const setShowQuotationTable = useFormStore.getState().setShowQuotationTable;
  ItemService.getItem(id, itemId)
    .then((response) => {
      if (response) {
        setFormData(response);

        if (response?.quotations?.length === 0) {
          setShowQuotationTable(false);
        } else {
          setShowQuotationTable(true);
        }
      }
    })
    .catch((err) => showError("Failed to load form data"));
}

export function getFormSchema(flowId) {
  const setFormSchema = useFormStore.getState().setFormSchema;
  WorkflowService.getLiveMetadata(flowId)
    .then((data) => {
      if (data) {
        setFormSchema(data);
      }
    })
    .catch((err) => showError("Failed to load live form metadata"));
}

export function getDraftFormSchema(flowId) {
  const setMetadata = useFormBuilderStore.getState().setMetadata;
  const setEditingField = useFormBuilderStore.getState().setEditingField;
  const setStatus = useFormBuilderStore.getState().setStatus;
  WorkflowService.getDraftMetadata(flowId)
    .then((metadata) => {
      let data, defaultActiveField;
      data = metadata;
      const phaseId = data.workflow.phases[0];
      const column = data[data[data[phaseId].rows[0]].columns[0]];

      defaultActiveField = data[column["field"]];
      setMetadata(data);
      setEditingField(defaultActiveField);
      getFormSchema(flowId);
      const formSchema = useFormBuilderStore.getState().metadata;
      if (!isEqual(formSchema, metadata)) {
        setStatus("draft");
      } else {
        setStatus("live");
      }
    })
    .catch((err) => showError("Failed to load draft form metadata"));
}
